import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'kit',
    meta: {
      packages: [Packages.KIT]
    },
    children: [
      {
        path: 'new',
        name: 'itemCompositionKitNew',
        component: () =>
          import('@/modules/register/modules/kit/ui/CompositionKitForm.vue'),
        meta: {
          pageTitle: 'Cadastrar kit',
        },
      },
      {
        path: 'edit/:id',
        name: 'itemCompositionKitEdit',
        props: true,
        component: () =>
          import('@/modules/register/modules/kit/ui/CompositionKitForm.vue'),
        meta: {
          pageTitle: 'Editar kit',
        },
      },
    ],
  },
];
