import { getCookieValue } from '@/shared/cookies';
import SessionService from './SessionService';

const Z_TOKEN = 'token' as string;

export const getToken = (): string | null => {
  return SessionService.get(Z_TOKEN) ?? getCookieValue('token') ?? null;
};

export const saveToken = (token: string): void => {
  return SessionService.set(Z_TOKEN, token);
};

export const destroyToken = (): void => {
  return SessionService.remove(Z_TOKEN);
};

export default { getToken, saveToken, destroyToken };
