export default [
  {
    path: '/fiscal/pdv',
    children: [
      {
        path: '',
        name: 'fiscalPdv',
        props: { isManagerial: false },
        component: () => import('@/modules/pdv/ui/PDV.vue'),
      },
    ],
  },
  {
    path: '/document/pdv',
    name: 'nonFiscalPdv',
    props: { isManagerial: true },
    component: () => import('@/modules/pdv/ui/PDV.vue'),
  },
];
