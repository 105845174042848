import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'shipay-configuration',
    name: 'shipayConfiguration',
    component: () => import('./ui/FiscalShipayConfigurationForm.vue'),
    meta: {
      packages: [Packages.SHIPAY]
    },
  },
];
