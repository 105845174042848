import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'client',
    meta: {
      packages: [Packages.CLIENT]
    },
    children: [
      {
        path: 'new',
        name: 'registerClientNew',
        component: () =>
          import(
            '@/modules/person/modules/client/ui/RegisterClientForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar cliente'
        }
      },
      {
        path: 'edit/:id',
        name: 'registerClientEdit',
        props: true,
        component: () =>
          import(
            '@/modules/person/modules/client/ui/RegisterClientForm.vue'
          ),
        meta: {
          pageTitle: 'Editar cliente'
        }
      }
    ]
  }
];
