const openFileUrl = (fileUrl: string, inNewWindow = false) => {
  if (!fileUrl) return;

  if (inNewWindow) {
    window.open(
      fileUrl,
      '_blank',
      `left=${(window.innerWidth - 794) / 2}`
    );
    return;
  }

  window.open(fileUrl, '_blank');
};

const base64ToBlob = (base64: string): Blob => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'application/pdf' });
};

const getBlobURL = (blob: Blob): string => {
  return window.URL.createObjectURL(blob);
};

export { openFileUrl, base64ToBlob, getBlobURL };
