import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'contact',
    meta: {
      packages: [Packages.CONTACTS]
    },
    children: [
      {
        path: 'new',
        name: 'registerContactNew',
        component: () =>
          import(
            '@/modules/register/modules/contact/ui/RegisterContactForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar contato'
        }
      },
      {
        path: 'edit/:id',
        name: 'registerContactEdit',
        props: true,
        component: () =>
          import(
            '@/modules/register/modules/contact/ui/RegisterContactForm.vue'
          ),
        meta: {
          pageTitle: 'Editar contato'
        }
      }
    ]
  }
];
