import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'expenses',
    meta: {
      packages: [Packages.EXPENSE]
    },
    children: [
      {
        path: 'new',
        name: 'newExpense',
        component: () =>
          import('@/modules/finance/modules/expenses/ui/FinanceExpensesForm.vue'),
        meta: {
          pageTitle: 'Cadastrar despesa'
        },
        props: true
      },
      {
        path: 'edit/:id',
        name: 'editExpense',
        component: () =>
          import('@/modules/finance/modules/expenses/ui/FinanceExpensesForm.vue'),
        meta: {
          pageTitle: 'Editar despesa'
        },
        props: true
      }
    ]
  }
];
