export default [
  {
    path: 'tributation-profiles',
    children: [
      {
        path: 'new',
        name: 'fiscalTributationProfileNew',
        component: () =>
          import(
            '@/modules/fiscal/modules/tributation-profiles/ui/FiscalTributationProfileForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar perfil de tributação',
        },
      },
      {
        path: 'edit/:id',
        name: 'fiscalTributationProfileEdit',
        props: true,
        component: () =>
          import(
            '@/modules/fiscal/modules/tributation-profiles/ui/FiscalTributationProfileForm.vue'
          ),
        meta: {
          pageTitle: 'Editar perfil de tributação',
        },
      },
    ],
  },
];
