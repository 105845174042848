import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'natureza',
    meta: {
      packages: [Packages.FISCAL],
    },
    children: [
      {
        path: 'new',
        name: 'fiscalNaturezaNew',
        component: () =>
          import('@/modules/fiscal/modules/natureza/ui/FiscalNaturezaForm.vue'),
        meta: {
          pageTitle: 'Cadastrar natureza',
        },
      },
      {
        path: 'edit/:id',
        name: 'fiscalNaturezaEdit',
        props: true,
        component: () =>
          import('@/modules/fiscal/modules/natureza/ui/FiscalNaturezaForm.vue'),
        meta: {
          pageTitle: 'Editar natureza',
        },
      },
    ],
  },
];
