import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'unit-of-measure',
    meta: {
      packages: [Packages.UNIT_OF_MEASURE]
    },
    children: [
      {
        path: 'new',
        name: 'registerUnitOfMeasureNew',
        component: () =>
          import(
            '@/modules/register/modules/unitOfMeasure/ui/RegisterUnitOfMeasureForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar unidade de medida'
        }
      },
      {
        path: 'edit/:id',
        name: 'registerUnitOfMeasureEdit',
        props: true,
        component: () =>
          import(
            '@/modules/register/modules/unitOfMeasure/ui/RegisterUnitOfMeasureForm.vue'
          ),
        meta: {
          pageTitle: 'Editar unidade de medida'
        }
      }
    ]
  }
];
