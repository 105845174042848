export default [
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () =>
      import('@/modules/login/modules/signUp/ui/components/SignUp.vue'),
    props: true,
    meta: {
      pageTitle: 'Cadastre-se',
    },
  },
];
