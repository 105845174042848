import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'tributacao',
    meta: {
      packages: [Packages.FISCAL]
    },
    children: [
      {
        path: 'new',
        name: 'fiscalTributacaoNew',
        component: () =>
          import('@/modules/fiscal/modules/tributacao/ui/FiscalNaturezaForm.vue'),
        meta: {
          pageTitle: 'Cadastrar tributação'
        }
      },
      {
        path: 'edit/:id',
        name: 'fiscalTributacaoEdit',
        props: true,
        component: () =>
          import('@/modules/fiscal/modules/tributacao/ui/FiscalNaturezaForm.vue'),
        meta: {
          pageTitle: 'Editar tributação'
        }
      }
    ]
  }
];
