import { Tooltip as BootstrapTooltip } from 'bootstrap';
import { closeTooltips } from '@/ui/global/mixins/tooltip';

import type { Plugin, PluginContext } from './index';

export const Tooltip: Plugin = {
  install(this: any, { parentApp }: PluginContext) {
    parentApp.directive('tooltip', (el, options) => {
      new BootstrapTooltip(el, { ...options.value, html: true });
    });

    window.addEventListener('click', () => {
      closeTooltips();
    });
  },
};
