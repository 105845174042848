export default [
  {
    path: '/',
    component: () => import('@/ui/zweb/layouts/main-layout/MainLayout.vue'),
    meta: {
      middleware: 'auth',
    },
    children: [
      {
        path: '/report/:reportName',
        name: 'CustomReport',
        props: true,
        component: () =>
          import('@/modules/dynamic/modules/report/ui/CustomReport.vue'),
      },
      {
        path: '/:module/:submodule',
        name: 'module',
        props: true,
        component: () => import('@/modules/dynamic/ui/Module.vue'),
        meta: {
          breadcrumbs: [''],
        },
      },
      {
        path: '/:module/:submodule/:submoduleChildren',
        name: 'submoduleChildren',
        props: true,
        component: () => import('@/modules/dynamic/ui/Module.vue'),
        meta: {
          breadcrumbs: [''],
        },
      },
    ],
  },
  {
    path: '/report/print/:module/:submodule/:submoduleChildren?',
    name: 'reportPrint',
    props: true,
    component: () => import('@/modules/dynamic/ui/ReportPrint.vue'),
    meta: {
      pageTitle: 'Imprimir relatório',
    },
  },
];
