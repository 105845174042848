import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'nfe',
    meta: {
      packages: [Packages.NFE]
    },
    children: [
      {
        path: 'new',
        name: 'fiscalNFeNew',
        component: () => import('@/modules/fiscal/modules/nfe/ui/FiscalNFeForm.vue'),
        meta: {
          pageTitle: 'Cadastrar NF-e'
        }
      },
      {
        path: 'edit/:id',
        name: 'fiscalNFeEdit',
        props: true,
        component: () => import('@/modules/fiscal/modules/nfe/ui/FiscalNFeForm.vue'),
        meta: {
          pageTitle: 'Editar NF-e'
        }
      }
    ]
  }
];
