import type { App } from 'vue';
import { zSymbol, type Globals } from './globals';

import { Modal } from './modal';
import { Tooltip } from './tooltip';
import { Mask } from './mask';
import { CurrentBrowser } from './current-browser';

export interface PluginObject {
  create?: any;
  __installed?: boolean;
}

export type PluginContext = { $z: Globals; parentApp: App };
export type Plugin<T = PluginObject> = T & {
  install: (ctx: PluginContext) => void;
};

const $z = {} as Globals;

export default function (parentApp: App) {
  parentApp.config.globalProperties.$z = $z as Globals;

  parentApp.provide(zSymbol, $z);

  // install plugins
  const plugins = <Plugin[]>[Modal, Tooltip, Mask, CurrentBrowser];
  plugins.forEach((Plugin) => {
    Plugin.install({ parentApp, $z });
    Plugin.__installed = true;
  });
}
