/* eslint-disable @typescript-eslint/no-unsafe-function-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ipcRenderer } from '#preload';
import clientEventEmitter from '@/external/services/event-emitter';

type IpcRenderer = typeof ipcRenderer;
type ClientEventEmitter = typeof clientEventEmitter;

export interface IEventManager {
  on(event: string, callback: Callback): void;
  once(event: string, callback: Callback): void;
  emit(event: string, ...args: any[]): void;
  invoke(event: string, ...args: any): Promise<any>;
  handle?(event: string, callback: Callback): void;
  listenerCount?(event: string, listener?: Function): number;
  removeAllListeners?(event?: string): void;
}

export type Callback = <T>(...data: T[]) => any;

export let eventManager: EventManager;

/**
 * Class used to handle events in client-side (front)
 * and back-side (electron, if exists)
 */
class EventManager implements IEventManager {
  constructor(
    private eventEmitter: ClientEventEmitter,
    private ipcRenderer?: IpcRenderer,
  ) {}

  public on(event: string, callback: Callback): void {
    this.eventEmitter.on(event, callback);
    this.ipcRenderer?.on(event, callback);
  }

  public once(event: string, callback: Callback): void {
    this.eventEmitter.once(event, callback);
    this.ipcRenderer?.once(event, callback);
  }

  public emit(event: string, ...args: any[]): void {
    this.eventEmitter.emit(event, ...args);
    this.ipcRenderer?.send(event, ...args);
  }

  public async invoke(event: string, ...args: any) {
    this.eventEmitter.invoke(event, ...args);
    this.ipcRenderer?.invoke(event, ...args);
  }

  /**
   * @description
   * [Node EventEmitter]
   * Electron "handle" implementation for the native EventEmitter
   */
  public async handle(event: string, callback: Callback) {
    this.eventEmitter.handle(event, callback);
  }

  /**
   * @description
   * [Node EventEmitter]
   * Remove all listeners from Node EventEmitter
   */
  public removeAllListeners() {
    this.eventEmitter.removeAllListeners();
  }

  /**
   * @description
   * [Node EventEmitter]
   * Remove all listeners from Node EventEmitter
   */
  listenerCount(event: string, listener?: Function): number {
    return this.eventEmitter.listenerCount(event, listener);
  }
}

export const createEventManager = () => {
  eventManager ??= new EventManager(clientEventEmitter, ipcRenderer);
  eventManager.removeAllListeners();

  return eventManager;
};
