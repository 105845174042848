import type { ObjectDirective } from 'vue';

export const Focus = {
  name: 'focus',
  directive: <ObjectDirective>{
    mounted(el, binding) {
      setTimeout(() => {
        if (!binding.value) return;
        el.focus();
      }, 600);
    },
  },
};
