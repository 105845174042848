import dayjs, { Dayjs } from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { convertDateTime } from './format';

dayjs.extend(customParseFormat);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('America/Sao_Paulo');

export const date = (
  date?: string | number | Date | dayjs.Dayjs | null,
  format?: string
) => dayjs(date, format);

export const formatDate = (
  date: Date | Dayjs | string,
  format = 'DD/MM'
): string => dayjs(date).format(format);

export const addDays = (date: Date | Dayjs, days: number): Dayjs =>
  dayjs(date).add(days, 'day');

export const subtractDays = (date: Date | Dayjs, days: number): Dayjs =>
  dayjs(date).subtract(days, 'day');

export const isBefore = (
  date: Date | Dayjs,
  otherDate: Date | Dayjs
): boolean => dayjs(date).isBefore(otherDate);

export const isAfter = (date: Date | Dayjs, otherDate: Date | Dayjs): boolean =>
  dayjs(date).isAfter(otherDate);

export const diffInDays = (
  startDate: Date | Dayjs,
  endDate: Date | Dayjs
): number => dayjs(endDate).diff(startDate, 'day');

export const getFormattedFirstDayOfMonth = (
  date: Date,
  format = 'YYYY-MM-DD'
) => {
  date.setDate(1);

  return convertDateTime(date, format);
};

export const getFormattedLastDayOfMonth = (
  date: Date,
  format = 'YYYY-MM-DD'
) => {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return convertDateTime(lastDay, format);
};

export const utc = (
  config?: string | number | Date | dayjs.Dayjs | null | undefined
) => dayjs.utc(config);

export default dayjs.utc;

export const ddmmyyyyToDate = (date: string, separator = '/') => {
  const [day, month, year] = date.split(separator).map(Number);

  return new Date(year, month - 1, day);
};

export const today = dayjs().format('YYYY-MM-DD');
export const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
