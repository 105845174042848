import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'supplier',
    meta: {
      packages: [Packages.SUPPLIER]
    },
    children: [
      {
        path: 'new',
        name: 'registerSupplierNew',
        component: () =>
          import(
            '@/modules/person/modules/supplier/ui/RegisterSupplierForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar fornecedor'
        }
      },
      {
        path: 'edit/:id/:accordion?',
        name: 'registerSupplierEdit',
        props: true,
        component: () =>
          import(
            '@/modules/person/modules/supplier/ui/RegisterSupplierForm.vue'
          ),
        meta: {
          pageTitle: 'Editar fornecedor'
        }
      }
    ]
  }
];
