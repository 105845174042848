import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'estimate',
    meta: {
      packages: [Packages.ESTIMATE]
    },
    children: [
      {
        path: 'new',
        name: 'newEstimate',
        component: () =>
          import(
            '@/modules/document/modules/estimate/ui/DocumentEstimateForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar orçamento'
        }
      },
      {
        path: 'edit/:id',
        name: 'editEstimate',
        props: true,
        component: () =>
          import(
            '@/modules/document/modules/estimate/ui/DocumentEstimateForm.vue'
          ),
        meta: {
          pageTitle: 'Editar orçamento'
        }
      }
    ]
  }
];
