export default [
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () =>
      import('@/modules/login/modules/signIn/ui/components/SignIn.vue'),
    meta: {
      pageTitle: 'Login',
    },
  },
];
