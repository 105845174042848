import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'accounts',
    meta: {
      packages: [Packages.ACCOUNT]
    },
    children: [
      {
        path: 'new',
        name: 'newAccount',
        component: () =>
          import(
            '@/modules/finance/modules/accounts/ui/FinanceAccountsForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar conta'
        },
        props: true
      },
      {
        path: 'edit/:id',
        name: 'editAccount',
        component: () =>
          import(
            '@/modules/finance/modules/accounts/ui/FinanceAccountsForm.vue'
          ),
        meta: {
          pageTitle: 'Editar conta'
        },
        props: true
      }
    ]
  }
];
