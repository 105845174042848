import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'price-table',
    meta: {
      packages: [Packages.PRICE_TABLE]
    },
    children: [
      {
        path: 'new',
        name: 'priceTableNew',
        component: () =>
          import(
            '@/modules/register/modules/price-table/ui/PriceTableForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar tabela de preços',
        },
      },
      {
        path: 'edit/:id',
        name: 'priceTableEdit',
        props: true,
        component: () =>
          import(
            '@/modules/register/modules/price-table/ui/PriceTableForm.vue'
          ),
        meta: {
          pageTitle: 'Editar tabela de preços',
        },
      },
    ],
  },
];
