export default [
  {
    path: 'company',
    children: [
      {
        path: '',
        name: 'accountCompany',
        component: () =>
          import('@/modules/account/modules/company/ui/CompanyForm.vue'),
      },
    ],
  },
];
