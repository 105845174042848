import financeGoalsRoutes from './modules/goals/routes';
import financeExpensesRoutes from './modules/expenses/routes';
import financeRevenuesRoutes from './modules/revenues/routes';
import financeAccountRoutes from './modules/accounts/routes';
import financeFlagRoutes from './modules/flags/routes';
import FinanceCardAdministratorRoutes from './modules/card-administrators/routes';
import financeInstallmentRoutes from './modules/installment/routes';
import financeCategoriesRoutes from './modules/categories/routes';
import FinanceTransfersRoutes from './modules/transfers/routes';
import FinanceChartOfAccountsRoutes from './modules/chart-of-accounts/routes';
import FinancePresentInIncomeStatementRoutes from './modules/present-in-income-statement/routes';

export default [
  {
    path: 'finance',
    redirect: '/',
    children: [
      ...financeExpensesRoutes,
      ...financeRevenuesRoutes,
      ...FinanceTransfersRoutes,
      {
        path: 'others',
        children: [
          ...financeGoalsRoutes,
          ...financeCategoriesRoutes,
          ...financeFlagRoutes,
          ...FinanceCardAdministratorRoutes,
          ...financeAccountRoutes,
          ...financeInstallmentRoutes,
        ],
      },
      {
        path: 'finance-management',
        children: [
          ...FinanceChartOfAccountsRoutes,
          ...FinancePresentInIncomeStatementRoutes,
        ]
      }
    ],
  },
];
