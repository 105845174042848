import type { SignInData } from '../entities/sign-in-data';
import type { ProfileData } from '../entities/profile-data';
import type { CompaniesAuthenticate } from '../entities/companies-authenticate-data';

import ApiService from '@/external/services/ApiService';
import ApiServiceV2 from '@/external/services/ApiServiceV2';
import TokenService from '@/external/services/TokenService';
import SessionService, { clear } from '@/external/services/SessionService';

import { defineStore } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { SubscriptionStatus } from '@/modules/account/modules/company/entities/subscription-status';
import { useAccountCompanyStore } from '@/modules/account/modules/company/store';
import { useApplicationCachedStore } from '@/modules/application/store/cached';
import { resetOptionsData } from '@/modules/register/ui/components/selects/ZSelectItem';
import ApiServiceV2NoAuth from '@/external/services/ApiServiceV2NoAuth';
import ApiServiceV1NoAuth from '@/external/services/ApiServiceV1NoAuth';
import websocket from '@/infrastructure/modules/websocket';

enum Endpoints {
  authenticate = 'application.authenticate',
  authenticateCompany = 'application.authenticate-company',
  getProfile = 'application.get-profile',
  authAdmin = 'application.auth-admin',
  sendRecoveryMail = 'application.send-recovery_mail',
  passwordRecovery = 'application.post-passwd_recovery',
  logout = 'application.logout',
}

const initialState = () => {
  return {
    login: '',
    password: '',
    isModulizedAccess: true,
  };
};

export default defineStore('authStore', {
  state: () => ({
    profile: {} as unknown as ProfileData,
    isAuthenticated: !!TokenService.getToken(),
    credentials: initialState() as SignInData,
    blocked: false,
    companies: [] as CompaniesAuthenticate,
    isResellerConnected: true,
  }),
  actions: {
    async logout() {
      await ApiService.post(Endpoints.logout);
      await this.purgeAuth();
    },
    async purgeAuth() {
      await Promise.all([
        this.resetStores(),
        (this.isAuthenticated = false),
        (this.credentials = initialState()),
        websocket.disconnect(),
        (this.isResellerConnected = false),
      ]);
    },
    async authenticateByCompany(uuid: string) {
      resetOptionsData();
      localStorage.removeItem('cacheService');
      await ApiServiceV2.post(Endpoints.authenticateCompany, {
        uuid,
      });
    },
    async resetStores() {
      await Promise.all([
        clear(),
        useApplicationCachedStore().$reset(),
        resetOptionsData(),
        localStorage.removeItem('cacheService'),
      ]);
    },
    async siginIn() {
      this.resetStores();

      this.credentials.login = this.credentials.login.trim();
      const { data } = await ApiServiceV2.post(Endpoints.authenticate, {
        ...this.credentials,
        isModulizedAccess: true,
      });

      TokenService.saveToken(data.access_token);

      if (data.companies) {
        this.companies = data.companies;
      }

      this.isAuthenticated = true;

      this.setResellerConnected(false);
    },
    async getProfile() {
      try {
        this.setHeader();
        const accountCompanyStore = useAccountCompanyStore();

        if (TokenService.getToken()) {
          const { data } = await ApiService.post(Endpoints.getProfile, {
            access_token: TokenService.getToken(),
          });

          this.profile = data;
          this.isAuthenticated = true;
          this.isResellerConnected = this.getResellerConnected();

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [account, subscription] = await Promise.allSettled([
            accountCompanyStore.getApplicationClient(),
            accountCompanyStore.getCurrentSubscription(),
            useConfigStore().getConfiguration(),
            accountCompanyStore.getMenu(),
          ]);

          if (
            subscription.status !== 'fulfilled' ||
            account.status !== 'fulfilled'
          )
            return true;

          const status = subscription.value.subscription_status;
          this.blocked = status === SubscriptionStatus.BlockedByOverdue;

          return true;
        }

        this.purgeAuth();

        return false;
      } catch (error) {
        this.purgeAuth();
        return false;
      }
    },
    async checkAuthAdmin(adminPassword: string): Promise<boolean> {
      const { data } = await ApiService.post(Endpoints.authAdmin, {
        adminPassword,
      });

      return data.isAdmin;
    },
    async forgotPassword() {
      const { data } = await ApiServiceV2NoAuth.post(
        Endpoints.sendRecoveryMail,
        {
          email: this.credentials.login,
          origin: import.meta.env.VITE_APP_URL,
        },
      );

      if (data.status) {
        return true;
      }

      return false;
    },
    async passwordRecovery(new_password: string, token: string) {
      const { data } = await ApiServiceV1NoAuth.post(
        Endpoints.passwordRecovery,
        {
          new_password,
          token,
        },
      );

      if (data.status) {
        return true;
      }

      return false;
    },
    setResellerConnected(status = false) {
      this.isResellerConnected = status;
      SessionService.set('isResellerConnected', `${status}`);
    },
    getResellerConnected() {
      return SessionService.get('isResellerConnected') === 'true'
        ? true
        : false;
    },
    setHeader() {
      ApiService.setHeader();
      ApiServiceV2.setHeader();
    },
  },
});
