import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'beneficio-fiscal',
    name: 'beneficioFiscalForm',
    component: () => import('./ui/BeneficioFiscalForm.vue'),
    meta: {
      packages: [Packages.FISCAL]
    },
  },
];
