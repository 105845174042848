import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'parametrizations',
    name: 'Parametrizations',
    component: () => import('./ui/FiscalParametrizationsForm.vue'),
    meta: {
      packages: [Packages.FISCAL]
    },
  },
];
