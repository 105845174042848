import { isNumber } from './isNumber';
import MathUtils from '@/shared/math-utils';

const findArray = <T extends Record<string, any>>(
  array: T[],
  column: keyof T,
  value: T[keyof T] | null,
): T | T[] | null => {
  if (!value) return array;
  return array.find((i) => i[column] === value) || null;
};

const filterArray = (array: Array<{}>, column: string, value: any) => {
  if (!value) return array;
  return array.filter((i: any) => i[column] == value);
};

/** @deprecated overengineering, its just a simple reduce */
const sumArray = (array: Array<{} | undefined> | undefined, column: string) => {
  if (!array) return 0;

  const total = array.reduce((total: number, e: any) => {
    if (!e) return total;

    return new MathUtils(total).add(e[column]).toNumber();
  }, 0) as number;

  if (!isNumber(total)) return 0;

  return total;
};

const renameObjectKey = (
  array: Array<{} | undefined> | undefined,
  from: string,
  to: string,
) => {
  if (!array) return [];

  array.forEach((objeto: any) => {
    objeto[to] = objeto[from];
    delete objeto[from];
  });

  return array;
};

const isEquals = (
  array1: Array<{} | undefined> | undefined,
  array2: Array<{} | undefined> | undefined,
) => {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

const isArrayOfObjects = (arr: any[]): boolean => {
  return Array.isArray(arr)
    ? arr.every((item) => typeof item === 'object')
    : [arr].every((item) => typeof item === 'object');
};

const sortArrayOfObjects = (
  propertyName: string,
  isAscending = true,
  caseSensitive = false,
) => {
  return function (a: any, b: any) {
    let valueA = a[propertyName];
    let valueB = b[propertyName];

    if (!caseSensitive) {
      if (typeof valueA === 'string') {
        valueA = valueA.toLowerCase();
      }

      if (typeof valueB === 'string') {
        valueB = valueB.toLowerCase();
      }
    }

    if (valueA < valueB) {
      return isAscending ? -1 : 1;
    }

    if (valueA > valueB) {
      return isAscending ? 1 : -1;
    }

    return 0;
  };
};

const sortByKey = (array: Array<{}>, key: any) => {
  return array.sort((a: any, b: any) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
};

export {
  isEquals,
  sumArray,
  findArray,
  filterArray,
  renameObjectKey,
  isArrayOfObjects,
  sortArrayOfObjects,
  sortByKey,
};
