/* eslint-disable */
import { inject } from '@vue/runtime-core';
import type { CurrentBrowser } from './current-browser';
import type { GlobalModal, GlobalModalPromise } from './modal';

export const zSymbol = '_z_';

export interface Globals {
  modal: GlobalModal;
  asyncModal: GlobalModalPromise;
  currentBrowser: CurrentBrowser;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $z: Globals;
  }
}

export const useGlobals = () => {
  return inject(zSymbol) as Globals;
};
