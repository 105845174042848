export default [
  {
    path: 'document-configuration',
    name: 'documentConfiguration',
    component: () => import('./ui/DocumentConfigurations.vue'),
    meta: {
      pageTitle: 'Configurações dos documentos'
    }
  },
  {
    path: 'document-configuration/new',
    name: 'newDocumentSituation',
    component: () =>
      import(
        '@/modules/document/modules/configurations/ui/components/DocumentSituationForm.vue'
      ),
    meta: {
      pageTitle: 'Cadastrar situação',
    },
  },
  {
    path: 'document-configuration/edit/:id',
    name: 'editDocumentSituation',
    props: true,
    component: () =>
      import(
        '@/modules/document/modules/configurations/ui/components/DocumentSituationForm.vue'
      ),
    meta: {
      pageTitle: 'Editar situação',
    },
  },
];
