import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'sale',
    meta: {
      packages: [Packages.SALE_ORDER]
    },
    children: [
      {
        path: 'new',
        name: 'newSale',
        component: () =>
          import('@/modules/document/modules/sale/ui/DocumentSaleForm.vue'),
        meta: {
          pageTitle: 'Cadastrar pedido de venda'
        }
      },
      {
        path: 'edit/:id',
        name: 'editSale',
        props: true,
        component: () =>
          import('@/modules/document/modules/sale/ui/DocumentSaleForm.vue'),
        meta: {
          pageTitle: 'Editar pedido de venda'
        }
      }
    ]
  }
];
