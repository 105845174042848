import fiscalNFeRouter from '@/modules/fiscal/modules/nfe/routes';
import fiscalNFceRouter from '@/modules/fiscal/modules/nfce/routes';
import fiscalNFSeRouter from '@/modules/fiscal/modules/nfse/modules/nfse/routes';
import fiscalNFSeTributesRouter from '@/modules/fiscal/modules/nfse/modules/tributes/routes';
import fiscalNFSeNaturesRouter from '@/modules/fiscal/modules/nfse/modules/natures/routes';
import fiscalNFSeConfigurationsRouter from '@/modules/fiscal/modules/nfse/modules/configurations/routes';
import fiscalNaturezaRouter from '@/modules/fiscal/modules/natureza/routes';
import fiscalPurchaseRouter from '@/modules/fiscal/modules/purchase/routes';
import fiscalTributacaoRouter from '@/modules/fiscal/modules/tributacao/routes';
import fiscalTributationProfileRouter from '@/modules/fiscal/modules/tributation-profiles/routes';
import fiscalCorrectionLetterRouter from '@/modules/fiscal/modules/correction-letter/routes';
import fiscalFinanceConciliationRouter from '@/modules/fiscal/modules/finance-conciliation/routes';
import fiscalInterestedActorRouter from '@/modules/fiscal/modules/interested-actor/routes';
import fiscalConfigurationsRouter from '@/modules/fiscal/modules/fiscal-configuration/routes';
import fiscalDocumentsAndCheckout from '@/modules/fiscal/modules/documents-and-checkouts/routes';
import fiscalBeneficioFiscalRouter from '@/modules/fiscal/modules/beneficio-fiscal/routes';
import fiscalParametrizationsRouter from '@/modules/fiscal/modules/parametrizations/routes';
import fiscalShipayConfigurationRouter from '@/modules/fiscal/modules/shipay-configuration/routes';
import fiscalMdfe from '@/modules/fiscal/modules/mdfe/modules/mdfe/routes';
import fiscalMdfeDriversRouter from '@/modules/fiscal/modules/mdfe/modules/drivers/routes';
import fiscalMdfeVehiclesRouter from '@/modules/fiscal/modules/mdfe/modules/vehicles/routes';
import fiscalMdfeThirdPartyOwnersRouter from '@/modules/fiscal/modules/mdfe/modules/third-party-owners/routes';
import fiscalMdfeConfigurationsRouter from '@/modules/fiscal/modules/mdfe/modules/configurations/routes';
import fiscalDevicesRouter from '@/modules/fiscal/modules/devices/routes';
import { Packages } from '../account/modules/company/enums';

export default [
  {
    path: '/fiscal',
    redirect: '/',
    children: [
      ...fiscalNFeRouter,
      ...fiscalNFceRouter,
      ...fiscalPurchaseRouter,
      ...fiscalShipayConfigurationRouter,
      {
        path: 'configurations',
        children: [
          ...fiscalDocumentsAndCheckout,
          ...fiscalBeneficioFiscalRouter,
          ...fiscalParametrizationsRouter,
          ...fiscalDevicesRouter,
          ...fiscalConfigurationsRouter,
          ...fiscalNaturezaRouter,
          ...fiscalTributacaoRouter,
          ...fiscalTributationProfileRouter,
        ],
      },
      {
        path: 'events',
        children: [
          ...fiscalCorrectionLetterRouter,
          ...fiscalFinanceConciliationRouter,
          ...fiscalInterestedActorRouter,
        ],
      },
      {
        path: 'mdfe',
        meta: {
          packages: [Packages.MDFE],
        },
        children: [
          ...fiscalMdfe,
          ...fiscalMdfeDriversRouter,
          ...fiscalMdfeVehiclesRouter,
          ...fiscalMdfeThirdPartyOwnersRouter,
          ...fiscalMdfeConfigurationsRouter,
        ],
      },
      {
        path: 'nfse',
        meta: {
          packages: [Packages.OS],
        },
        children: [
          ...fiscalNFSeRouter,
          ...fiscalNFSeTributesRouter,
          ...fiscalNFSeNaturesRouter,
          ...fiscalNFSeConfigurationsRouter,
        ],
      },
    ],
  },
];
