export default [
  {
    path: 'profile',
    name: 'userProfile',
    component: () => import('./UserProfileForm.vue'),
    meta: {
      pageTitle: 'Editar perfil'
    }
  }
];
