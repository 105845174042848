import registerProductRoutes from '@/modules/register/modules/product/routes';
import registerGroupRoutes from '@/modules/register/modules/group/routes';
import registerUnitOfMeasure from '@/modules/register/modules/unitOfMeasure/routes';
import registerContact from '@/modules/register/modules/contact/routes';
import variation from '@/modules/register/modules/variation/routes';
import personRoutes from '@/modules/person';
import registerServiceRoutes from '@/modules/register/modules/service/routes';
import registerKitRoutes from '@/modules/register/modules/kit/routes';
import registerPriceTableRoutes from '@/modules/register/modules/price-table/routes';

export default [
  {
    path: '/register',
    redirect: '/',
    children: [
      ...personRoutes,
      ...registerContact,
      {
        path: 'stock',
        children: [
          ...registerProductRoutes,
          ...registerGroupRoutes,
          ...registerUnitOfMeasure,
          ...variation,
          ...registerServiceRoutes,
          ...registerKitRoutes,
          ...registerPriceTableRoutes,
        ],
      },
    ],
  },
];
