import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'variation',
    meta: {
      packages: [Packages.VARIATION]
    },
    children: [
      {
        path: 'new',
        name: 'variationNew',
        component: () =>
          import('@/modules/register/modules/variation/ui/Form.vue'),
        meta: {
          pageTitle: 'Cadastrar registro'
        }
      },
      {
        path: 'edit/:id',
        name: 'variationEdit',
        props: true,
        component: () =>
          import('@/modules/register/modules/variation/ui/Form.vue'),
        meta: {
          pageTitle: 'Editar registro'
        }
      }
    ]
  }
];
