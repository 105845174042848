export default [
  {
    path: '/',
    component: () => import('@/ui/global/layouts/SystemLayout.vue'),
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import('@/modules/application/pages/error/404.vue'),
        meta: {
          pageTitle: 'Error 404'
        }
      },
      {
        path: '/package-not-allowed',
        name: 'packageNotAllowed',
        component: () => import('@/modules/application/pages/error/PackageNotAllowed.vue'),
        meta: {
          pageTitle: 'Acesso não permitido'
        }
      },
      {
        path: '/terms',
        name: 'terms',
        component: () => import('@/modules/application/pages/terms/Terms.vue'),
        meta: {
          pageTitle: 'Termos de Contrato de Software'
        }
      }
    ]
  }
];
