export default [
  {
    path: '/companies-menu',
    name: 'companies-menu',
    props: true,
    component: () =>
      import(
        '@/modules/login/modules/companies-menu/ui/components/CompaniesMenu.vue'
      ),
    meta: {
      pageTitle: 'Selecione a empresa',
    },
  },
];
