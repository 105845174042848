import { storeToRefs } from 'pinia';
import { useAccountCompanyStore } from '@/modules/account/modules/company/store';

const getRouteByPath = (route: string, prefix: string) =>
  route.split(prefix)[0] ?? route;

export const accessRoutes = (routerPath: string) => {
  let requiredRoute =  routerPath.split('#')[0] ?? routerPath;
  const accountCompanyStore = useAccountCompanyStore();
  const { subscriptionRoutes } = storeToRefs(accountCompanyStore);

  if (!subscriptionRoutes.value.length) return true;

  if (requiredRoute.indexOf('/new') !== -1)
    requiredRoute = getRouteByPath(requiredRoute, '/new');
  if (requiredRoute.indexOf('/edit') !== -1)
    requiredRoute = getRouteByPath(requiredRoute, '/edit');
  if (requiredRoute.indexOf('/start') !== -1)
    requiredRoute = getRouteByPath(requiredRoute, '/start');
  if (requiredRoute.indexOf('report') !== -1) return true;

  return subscriptionRoutes.value.some((route) => route == requiredRoute);
};
