<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import { useConfigStore } from '@/stores/config';
import { initializeComponents } from '@/ui/global/plugins/theme';
import { useAuthStore } from '@/modules/login/modules/signIn/store';

export default defineComponent({
  name: 'App',
  components: {
    RouterView,
  },
  setup() {
    const authStore = useAuthStore();
    const configStore = useConfigStore();

    onMounted(async () => {
      authStore.setHeader();

      configStore.overrideLayoutConfig();
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>

<style lang="scss">
@import 'ui/global/assets/sass/style';
</style>
