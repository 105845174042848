import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'present-in-income-statement',
    meta: {
      packages: [Packages.CHART_OF_ACCOUNTS],
    },
    children: [
      {
        path: '',
        name: 'presentInIncomeStatement',
        component: () =>
          import(
            '@/modules/finance/modules/present-in-income-statement/ui/FinancePresentInIncomeStatement.vue'
          ),
      },
    ],
  },
];
