import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'documents-and-checkouts',
    name: 'DocumentsAndCheckouts',
    component: () => import('./ui/DocumentsAndCheckouts.vue'),
    meta: {
      packages: [Packages.FISCAL]
    },
  },
];
