import type { ProductData } from '@/modules/register/modules/product/entities';
import type { SummaryServiceData } from '@/modules/register/modules/service/entities';
import { computed, ref } from 'vue';

const servicesData = ref<SummaryServiceData[]>([]);
const productsData = ref<ProductData[]>([]);
const kitsData = ref<ProductData[]>([]);
const hasSearchForVariation = ref(false);

const options = computed(() => {
  return [...servicesData.value, ...productsData.value, ...kitsData.value].sort(
    (a, b) => (b.sequence as number) - (a.sequence as number)
  );
});

const resetOptionsData = () => {
  servicesData.value = [];
  productsData.value = [];
  kitsData.value = [];
};

export { options, servicesData, productsData, kitsData, resetOptionsData, hasSearchForVariation };
