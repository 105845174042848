/* eslint-disable @typescript-eslint/no-unsafe-function-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from 'events';
import type { Callback } from '@/infrastructure/modules/event-manager';

const eventEmitter = new EventEmitter();
const _callbacks: Record<string, { callback: Callback }[]> = {};

export default {
  emit(event: string, data?: any): void {
    eventEmitter.emit(event, data);
  },

  on(event: string, callback: Callback): void {
    eventEmitter.on(event, callback);
  },

  once(event: string, callback: Callback): void {
    eventEmitter.once(event, callback);
  },

  async invoke(event: string, ...args: any) {
    if (!_callbacks[event])
      return event === 'error' ? Promise.reject(args[0]) : Promise.resolve();

    const chain: Callback[] = [];

    for (const fn of _callbacks[event]) {
      chain.push(fn.callback(...args));
    }

    return Promise.all(chain);
  },

  async handle(event: string, callback: Callback) {
    if (typeof callback !== 'function') return;

    if (!_callbacks[event]) _callbacks[event] = [];

    _callbacks[event].push({ callback });
  },

  removeAllListeners(): void {
    eventEmitter.removeAllListeners();
  },

  listenerCount(event: string, listener?: Function) {
    return eventEmitter.listenerCount(event, listener);
  },
};
