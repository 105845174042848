export default [
  {
    path: 'third-party-owners',
    children: [
      {
        path: 'new',
        name: 'newThirdPartyOwner',
        component: () =>
          import(
            '@/modules/fiscal/modules/mdfe/modules/third-party-owners/ui/FiscalMdfeThirdPartyOwners.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar terceiro',
        },
      },
      {
        path: 'edit/:id',
        name: 'editThirdPartyOwner',
        props: true,
        component: () =>
          import(
            '@/modules/fiscal/modules/mdfe/modules/third-party-owners/ui/FiscalMdfeThirdPartyOwners.vue'
          ),
        meta: {
          pageTitle: 'Editar terceiro',
        },
      },
    ],
  },
];
