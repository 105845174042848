import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { initVeeValidate } from '@/ui/global/plugins/vee-validate';
import VueNumberFormat from '@coders-tm/vue-number-format';

import App from './App.vue';
import router from './router';
import ApiService from '@/external/services/ApiService';
import ApiServiceV2 from '@/external/services/ApiServiceV2';
import VueGtag from 'vue-gtag';
import plugins from '@/plugins';
import directives from '@/directives';
import ApiServiceV1NoAuth from './external/services/ApiServiceV1NoAuth';
import ApiServiceV2NoAuth from './external/services/ApiServiceV2NoAuth';

const app = createApp(App);

app.use(VueNumberFormat);
app.use(createPinia());
app.use(router);

if (import.meta.env.MODE === 'production') {
  app.use(
    VueGtag,
    {
      appName: import.meta.env.VITE_APP_NAME,
      pageTrackerScreenviewEnabled: true,
      config: { id: import.meta.env.VITE_MEASUREMENT_ID },
    },
    router,
  );
}

initVeeValidate();

ApiService.init();
ApiServiceV2.init();
ApiServiceV1NoAuth.init();
ApiServiceV2NoAuth.init();

app.use(plugins);
app.use(directives);
app.mount('#app');
