import accountCompanyRoutes from './modules/company/routes';
import accountUserRoutes from './modules/users/routes';
import accountAccountantRoutes from './modules/accountant/routes';
import accountGeneralConfigurationsRoutes from './modules/configuration/routes';

export default [
  {
    path: '/account',
    redirect: '/',
    children: [
      ...accountCompanyRoutes,
      ...accountUserRoutes,
      ...accountAccountantRoutes,
      ...accountGeneralConfigurationsRoutes
    ]
  }
];
