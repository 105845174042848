import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'chart-of-accounts',
    meta: {
      packages: [Packages.CHART_OF_ACCOUNTS],
    },
    children: [
      {
        path: '',
        name: 'chartOfAccounts',
        component: () =>
          import(
            '@/modules/finance/modules/chart-of-accounts/ui/FinanceChartOfAccountsForm.vue'
          ),
      },
    ],
  },
];
