import { ref } from 'vue';

const nodesList = ref<HTMLElement[]>([]);
const portals = ref<{ [key: string]: any }>({});
let portalIndex = 1;

export const createGlobalNode = (portal: string, opts = {} as any) => {
  const el = document.createElement('div');

  el.id = `portal--${portal}--${portalIndex++}`;

  document.body.appendChild(el);
  nodesList.value.push(el);
  opts.id = el.id;
  portals.value[el.id] = opts;

  return el;
};

export const removeGlobalNode = (el: HTMLElement) => {
  const index = nodesList.value.indexOf(el);

  nodesList.value.splice(index, 1);
  delete portals.value[el.id];

  el.remove();
};

export { nodesList, portals };
