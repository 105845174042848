import type {
  LayoutConfig,
  LayoutConfiguration,
  LayoutSaveConfiguration,
} from '@/ui/global/shared/layout-config-types';
import type { SharedMenuItem } from '@/ui/global/shared/types';

import { defineStore } from 'pinia';
import objectPath from 'object-path';
import { headerLight } from '@/ui/zweb/config/DefaultLayoutConfig';
import ApiServiceV2 from '@/external/services/ApiServiceV2';
import { defineAsyncComponent } from 'vue';
import type { QuickAccessOption } from '@/ui/global/layouts/main-layout/extras/CustomizeQuickAccess.vue';

export const Z_CONFIG_KEY = 'z_theme_config_' + import.meta.env.VITE_APP_NAME;
export const Z_CONFIG_GRID = 'z_theme_config_grid';
export const Z_THEME_MODE = 'z_theme_mode';

export const ApiEndPoint = {
  getConfigurationLayout: 'application.get-user-configuration-layout',
  saveConfigurationLayout: 'application.post-user-configuration-layout',
};

type AsyncComponentType = ReturnType<typeof defineAsyncComponent>;

export const useConfigStore = defineStore('useConfigStore', {
  state: () => ({
    config: headerLight.theme_configuration as LayoutConfig,
    initial: headerLight.theme_configuration as LayoutConfig,
    mode: localStorage.getItem(Z_THEME_MODE) as 'light' | 'dark',
    grid_configuration: [] as LayoutConfiguration['grid_configuration'],
    default_values:
      {} as LayoutConfiguration['default_values'],
    quick_access_configuration: [
      { label: 'Configure já', quickAccess: true },
    ] as QuickAccessOption[],
    dashboard_configuration: {} as SharedMenuItem,
    actionComponent: {
      component: null as AsyncComponentType | null,
    },
  }),
  actions: {
    setThemeMode(currentMode: 'light' | 'dark') {
      localStorage.setItem(Z_THEME_MODE, currentMode);
      localStorage.setItem(Z_THEME_MODE, currentMode);
      document.documentElement.setAttribute('data-bs-theme', currentMode);
    },
    async getConfiguration() {
      try {
        const { data } = await ApiServiceV2.post(
          ApiEndPoint.getConfigurationLayout
        );
        this.config = data.theme_configuration;
        this.grid_configuration = data.grid_configuration;
        if (
          Array.isArray(data.quick_access_configuration) &&
          data.quick_access_configuration.length
        ) {
          this.quick_access_configuration = data.quick_access_configuration;
        }
        this.dashboard_configuration = data.dashboard_configuration;
        this.default_values = data.default_values;
        this.setThemeMode(data.theme);
        this.setLayoutConfigProperty(
          'general.layout',
          data.theme_configuration.general.layout
        );
        this.setLayoutConfigProperty('general.mode', data.theme);
        localStorage.setItem(Z_CONFIG_KEY, JSON.stringify(this.config));
        localStorage.setItem(
          Z_CONFIG_GRID,
          JSON.stringify(this.grid_configuration)
        );
      } catch (error) {
        this.overrideLayoutConfig();
      }
    },
    async saveconfiguration(config: LayoutConfiguration) {
      await ApiServiceV2.post(ApiEndPoint.saveConfigurationLayout, config);
      localStorage.setItem(
        Z_CONFIG_KEY,
        JSON.stringify(config.theme_configuration)
      );
    },
    getLayoutConfig(path: string, defaultValue?: string) {
      return objectPath.get(this.config, path, defaultValue);
    },
    setLayoutConfigProperty(property: string, value: any) {
      objectPath.set(this.config, property, value);
      localStorage.setItem(Z_CONFIG_KEY, JSON.stringify(this.config));
    },
    overrideLayoutConfig() {
      this.config = this.initial = Object.assign(
        {},
        this.initial,
        JSON.parse(window.localStorage.getItem(Z_CONFIG_KEY) || '{}')
      );
    },
    resetActionComponent() {
      this.actionComponent = {
        component: null,
      };
    },
    async saveLayoutConfiguration(config: LayoutSaveConfiguration) {
      await this.saveconfiguration({
        theme_configuration: config?.theme_configuration ?? this.config,
        theme: config?.theme ?? this.config.general.mode,
        grid_configuration:
          config?.grid_configuration ?? this.grid_configuration,
        quick_access_configuration:
          config?.quick_access_configuration ?? this.quick_access_configuration,
        dashboard_configuration:
          config?.dashboard_configuration ?? this.dashboard_configuration,
        default_values:
          config?.default_values ?? this.default_values,
      } as LayoutConfiguration);
    },
  },
});
