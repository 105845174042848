import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'identifiers',
    name: 'serviceOrderIdentifiers',
    meta: {
      packages: [Packages.OS]
    },
    component: () =>
      import(
        '@/modules/document/modules/service-order/modules/identifiers/ui/DocumentIdentifiersForm.vue'
      ),
  },
];
