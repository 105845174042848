export default [
  {
    path: 'new',
    name: 'newServiceOrder',
    component: () =>
      import(
        '@/modules/document/modules/service-order/modules/service-order/ui/DocumentServiceOrderForm.vue'
      ),
    meta: {
      pageTitle: 'Cadastrar ordem de serviço',
    },
  },
  {
    path: 'edit/:id',
    name: 'editServiceOrder',
    props: true,
    component: () =>
      import(
        '@/modules/document/modules/service-order/modules/service-order/ui/DocumentServiceOrderForm.vue'
      ),
    meta: {
      pageTitle: 'Editar ordem de serviço',
    },
  },
];
