export default [
  {
    path: 'tributes',
    children: [
      {
        path: 'new',
        name: 'newNfseTribute',
        component: () =>
          import(
            '@/modules/fiscal/modules/nfse/modules/tributes/ui/FiscalNFSeTributesForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar tributação',
        },
      },
      {
        path: 'edit/:id',
        name: 'editNfseTribute',
        props: true,
        component: () =>
          import(
            '@/modules/fiscal/modules/nfse/modules/tributes/ui/FiscalNFSeTributesForm.vue'
          ),
        meta: {
          pageTitle: 'Editar tributação',
        },
      },
    ],
  },
];
