import signInRoutes from '@/modules/login/modules/signIn/routes';
import signUpRoutes from '@/modules/login/modules/signUp/routes';
import companiesMenuRoutes from '@/modules/login/modules/companies-menu/routes';
import resetPasswordRoutes from '@/modules/login/modules/resetPassword/routes';
import forgotPasswordRoutes from '@/modules/login/modules/forgotPassword/routes';
import resellerAuthenticateRoutes from '@/modules/login/modules/resellerAuthenticate/routes';

export default [
  {
    path: '/',
    component: () => import('@/ui/zweb/layouts/auth-layout/AuthLayout.vue'),
    children: [
      ...signInRoutes,
      ...signUpRoutes,
      ...resetPasswordRoutes,
      ...forgotPasswordRoutes,
      ...resellerAuthenticateRoutes,
    ],
  },
  ...companiesMenuRoutes,
];
