import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: '/zhub',
    redirect: '/',
    meta: {
      packages: [Packages.ZHUB]
    },
    children: [
      {
        path: '/integration/zhub/start',
        name: 'zhub-start',
        component: () => import('./ui/ZHubStartIntegration.vue'),
      },
    ],
  },
];
