import type { RouteLocationNormalized } from 'vue-router';

export default [
  {
    path: 'new',
    name: 'newMdfe',
    component: () =>
      import(
        '@/modules/fiscal/modules/mdfe/modules/mdfe/ui/FiscalMdfeForm.vue'
      ),
    meta: {
      pageTitle: 'Cadastrar MDF-e',
    },
  },
  {
    path: 'new',
    name: 'newMdfeFromNfe',
    component: () =>
      import(
        '@/modules/fiscal/modules/mdfe/modules/mdfe/ui/FiscalMdfeForm.vue'
      ),
    meta: {
      pageTitle: 'Cadastrar MDF-e',
    },
    props: (route: RouteLocationNormalized) => ({ nfeId: route.query.nfeId }),
  },
  {
    path: 'edit/:id',
    name: 'editMdfe',
    props: true,
    component: () =>
      import(
        '@/modules/fiscal/modules/mdfe/modules/mdfe/ui/FiscalMdfeForm.vue'
      ),
    meta: {
      pageTitle: 'Editar MDF-e',
    },
  },
];
