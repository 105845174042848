export default [
  {
    path: 'natures',
    children: [
      {
        path: 'new',
        name: 'newNfseNature',
        component: () =>
          import(
            '@/modules/fiscal/modules/nfse/modules/natures/ui/FiscalNFSeNaturesForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar natureza',
        },
      },
      {
        path: 'edit/:id',
        name: 'editNfseNature',
        props: true,
        component: () =>
          import(
            '@/modules/fiscal/modules/nfse/modules/natures/ui/FiscalNFSeNaturesForm.vue'
          ),
        meta: {
          pageTitle: 'Editar natureza',
        },
      },
    ],
  },
];
