import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'managerial',
    meta: {
      packages: [Packages.MANAGERIAL]
    },
    children: [
      {
        path: 'new',
        name: 'documentManagerialNew',
        props: {
          isManagerial: true,
        },
        component: () =>
          import('@/modules/fiscal/modules/nfce/ui/FiscalNFCeForm.vue'),
        meta: {
          pageTitle: 'Cadastrar venda gerencial',
        },
      },
      {
        path: 'edit/:id',
        name: 'documentManagerialEdit',
        props: (route: any) => ({ id: route.params.id, isManagerial: true }),
        component: () =>
          import('@/modules/fiscal/modules/nfce/ui/FiscalNFCeForm.vue'),
        meta: {
          pageTitle: 'Editar gerencial',
        },
      },
    ],
  },
];
