import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'trade-status',
    meta: {
      packages: [Packages.SALE_ORDER],
    },
    children: [
      {
        path: 'new',
        name: 'newDavSituation',
        component: () =>
          import(
            '@/modules/document/modules/trade-status/ui/TradeStatusForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar situação',
        },
      },
      {
        path: 'edit/:id',
        name: 'editDavSituation',
        props: true,
        component: () =>
          import(
            '@/modules/document/modules/trade-status/ui/TradeStatusForm.vue'
          ),
        meta: {
          pageTitle: 'Editar situação',
        },
      },
    ],
  },
];
