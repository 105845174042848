import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'purchase',
    meta: {
      packages: [Packages.PURCHASE],
    },
    children: [
      {
        path: 'new',
        name: 'purchaseNew',
        component: () =>
          import('@/modules/fiscal/modules/purchase/ui/Form.vue'),
        meta: {
          pageTitle: 'Cadastrar compra',
        },
      },
      {
        path: 'edit/:id',
        name: 'purchaseEdit',
        props: true,
        component: () =>
          import('@/modules/fiscal/modules/purchase/ui/Form.vue'),
        meta: {
          pageTitle: 'Editar compra',
        },
      },
    ],
  },
];
