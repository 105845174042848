import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'transfers',
    meta: {
      packages: [Packages.TRANSFER]
    },
    children: [
      {
        path: 'new',
        name: 'newTransfer',
        component: () =>
          import('@/modules/finance/modules/transfers/ui/FinanceTransfersForm.vue'),
        meta: {
          pageTitle: 'Cadastrar transferência'
        },
        props: true
      },
      {
        path: 'edit/:id',
        name: 'editTransfer',
        component: () =>
          import('@/modules/finance/modules/transfers/ui/FinanceTransfersForm.vue'),
        meta: {
          pageTitle: 'Editar transferência'
        },
        props: true
      }
    ]
  }
];
