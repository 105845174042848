import Big from 'big.js';

interface IMathUtils {
  add(value: number | string): IMathUtils;
  subtract(value: number | string): IMathUtils;
  multiply(value: number | string): IMathUtils;
  divide(value: number | string): IMathUtils;
  round(precision: number): IMathUtils;
  toNumber(): number;
}

class MathUtils implements IMathUtils {
  private big: Big;

  constructor(value: number | string) {
    this.big = new Big(value || 0);
  }

  add(value: number | string) {
    this.big = this.big.plus(value || 0);
    return this;
  }

  subtract(value: number | string) {
    this.big = this.big.minus(value || 0);
    return this;
  }

  multiply(value: number | string) {
    this.big = this.big.times(value || 0);
    return this;
  }

  divide(value: number | string) {
    this.big = this.big.div(value || 0);
    return this;
  }

  toNumber() {
    return this.big.toNumber();
  }

  round(precision: number) {
    this.big = this.big.round(precision);

    return this;
  }
}

export default MathUtils;
