import documentSaleRoutes from '@/modules/document/modules/sale/routes';
import documentEstimateRoutes from '@/modules/document/modules/estimate/routes';
import documentManagerialRoutes from '@/modules/document/modules/managerial/routes';
import documentServiceOrderRoutes from '@/modules/document/modules/service-order/modules/service-order/routes';
import documentPurchaseOrderRoutes from '@/modules/document/modules/purchase-order/routes';
import documentConfigurationsRoutes from '@/modules/document/modules/configurations/routes';
import documentServiceOrderObjectsRoutes from '@/modules/document/modules/service-order/modules/objects/routes';
import documentServiceOrderSituationsRoutes from '@/modules/document/modules/service-order/modules/situations/routes';
import documentServiceOrderIdentifiersRoutes from '@/modules/document/modules/service-order/modules/identifiers/routes';
import documentServiceOrderTypesOfServiceRoutes from '@/modules/document/modules/service-order/modules/types-of-service/routes';
import documentServiceOrderConfigurationsRoutes from '@/modules/document/modules/service-order/modules/configurations/routes';
import documentDavSituationsRoutes from '@/modules/document/modules/trade-status/routes';

import { Packages } from '../account/modules/company/enums';

export default [
  {
    path: '/document',
    redirect: '/',
    children: [
      ...documentManagerialRoutes,
      ...documentPurchaseOrderRoutes,
      ...documentConfigurationsRoutes,
      {
        path: 'davs',
        children: [
          ...documentSaleRoutes,
          ...documentEstimateRoutes,
          ...documentDavSituationsRoutes,
        ],
      },
      {
        path: 'service-order',
        meta: {
          packages: [Packages.OS],
        },
        children: [
          ...documentServiceOrderRoutes,
          ...documentServiceOrderObjectsRoutes,
          ...documentServiceOrderSituationsRoutes,
          ...documentServiceOrderIdentifiersRoutes,
          ...documentServiceOrderConfigurationsRoutes,
          ...documentServiceOrderTypesOfServiceRoutes,
        ],
      },
    ],
  },
];
