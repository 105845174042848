import personClientRotues from '@/modules/person/modules/client/routes';
import personSupplierRotues from '@/modules/person/modules/supplier/routes';

export default [
  {
    path: '/register',
    redirect: '/',
    children: [...personClientRotues, ...personSupplierRotues]
  }
];
