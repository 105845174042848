export default [
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
      import(
        '@/modules/login/modules/forgotPassword/ui/components/ForgotPassword.vue'
      ),
    props: true,
    meta: {
      pageTitle: 'Esqueci minha senha',
    },
  },
];
