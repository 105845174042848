/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'axios';
import TokenService from '@/external/services/TokenService';
import toast from '@/ui/global/plugins/toast';
import { setShowLoader } from '@/ui/global/shared/loader';
import cacheService from './CacheService';
import WebSocket from '@/infrastructure/modules/websocket';
import { confirm } from '@/ui/global/shared/message';
import axiosRateLimit from 'axios-rate-limit';
import router from '@/router';

class ApiService {
  protected static http: AxiosInstance;
  protected static version = 1;

  public static init() {
    const maxRequests =
      import.meta.env.VITE_APP_ENVIROMENT == 'DEVELOPMENT' ? 50 : 10;

    this.http = axiosRateLimit(
      axios.create({
        baseURL: `${import.meta.env.VITE_APP_API_URL}v${this.version}/`,
        withCredentials: true,
      }),
      {
        maxRequests,
        perMilliseconds: 2000,
      },
    );

    this.http.interceptors.request.use((config) => {
      config.headers['Socket-Id'] = WebSocket.getId();

      return config;
    });

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error);
        setShowLoader(false);

        const message = error?.response?.data?.error_message,
          name = error?.response?.data?.error_name;

        if (name === 'RoomNotFound') {
          confirm(message, undefined, 'Ok', '');
          return Promise.reject(error);
        }

        if (name === 'NoModel') {
          confirm(message, undefined, 'Ok', '');
          return Promise.reject(error);
        }

        if (name === 'AccessTokenCannotBeEmpty') {
          toast.clear();
          toast.error(
            'Por favor, efetue o login novamente para acesso a conta!',
          );
          router.push({ name: 'sign-in' });
          return;
        }

        if (message) {
          toast.clear();
          toast.error(message);
        }

        if (error.response?.data.error_code == 401) {
          router.push({ name: 'sign-in' });
          return;
        }

        return Promise.reject(error);
      },
    );
  }

  public static getInstance() {
    return this.http;
  }

  public static setHeader(): void {
    this.http.defaults.headers.common['Accept'] = 'application/json';
    this.http.defaults.headers.common['Authorization-Compufacil'] =
      TokenService.getToken();
  }

  public static get<T>(url: string): Promise<AxiosResponse<T>> {
    return this.http.get(url);
  }

  public static async postWithCache<T = any, P = any>(
    url: string,
    params?: P,
    cache: { key: string } = { key: '' },
    config?: AxiosRequestConfig,
  ) {
    const cacheKey = cache.key || '';
    const data = cacheService.loadCache(cacheKey);

    if (data) return Promise.resolve({ data } as AxiosResponse<T>);

    const response = await this.post<T, P>(url, params, config);
    cacheService.setCache(cacheKey, response.data);

    return response;
  }

  public static async post<T = any, P = any>(
    url: string,
    params?: P,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.http.post<P, AxiosResponse<T>>(url, params, config);
  }

  public static put<T = any, P = any>(
    url: string,
    params: P,
  ): Promise<AxiosResponse<T>> {
    return this.http.put<P, AxiosResponse<T>>(url, params);
  }

  public static delete(url: string): Promise<AxiosResponse> {
    return this.http.delete(url);
  }
}

export default ApiService;
