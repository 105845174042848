import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'service',
    meta: {
      packages: [Packages.OS]
    },
    children: [
      {
        path: 'new',
        name: 'registerServiceNew',
        component: () =>
          import('@/modules/register/modules/service/ui/RegisterServices.vue'),
        meta: {
          pageTitle: 'Cadastrar serviço',
        },
      },
      {
        path: 'edit/:id',
        name: 'registerServiceEdit',
        props: true,
        component: () =>
          import('@/modules/register/modules/service/ui/RegisterServices.vue'),
        meta: {
          pageTitle: 'Editar serviço',
        },
      },
    ],
  },
];
