export default [
  {
    path: 'users',
    children: [
      {
        path: 'new',
        name: 'newUsers',
        component: () => import('./ui/AccountUserForm.vue'),
        meta: {
          pageTitle: 'Cadastrar usuário'
        }
      },
      {
        path: 'edit/:id',
        name: 'editUsers',
        component: () => import('./ui/AccountUserForm.vue'),
        meta: {
          pageTitle: 'Editar usuário'
        },
        props: true
      }
    ]
  }
];
