import { createApp, type App, type Component } from 'vue';

export function createChildApp(appCfg: Component, parentApp: App) {
  const app = createApp(appCfg);

  app.config.globalProperties = parentApp.config.globalProperties;

  const { ...appContext } = parentApp._context;
  Object.assign(app._context, appContext);

  return app;
}
