import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'product',
    meta: {
      packages: [Packages.PRODUCT],
    },
    children: [
      {
        path: 'new',
        name: 'registerProductNew',
        component: () =>
          import(
            '@/modules/register/modules/product/ui/RegisterProductForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar produto',
        },
      },
      {
        path: 'edit/:id/:variationId?',
        name: 'registerProductEdit',
        props: true,
        component: () =>
          import(
            '@/modules/register/modules/product/ui/RegisterProductForm.vue'
          ),
        meta: {
          pageTitle: 'Editar produto',
        },
      },
    ],
  },
];
