import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'card-administrator',
    meta: {
      packages: [Packages.CARD_ADMINISTRATOR]
    },
    children: [
      {
        path: 'new',
        name: 'newCardAdministrator',
        component: () => import('./ui/FinanceCardAdministratorForm.vue'),
        meta: {
          pageTitle: 'Cadastrar administradora de cartões'
        }
      },
      {
        path: 'edit/:id',
        name: 'editCardAdministrator',
        component: () => import('./ui/FinanceCardAdministratorForm.vue'),
        meta: {
          pageTitle: 'Editar administradora de cartões'
        },
        props: true
      }
    ]
  }
];
