export default [
  {
    path: 'new',
    name: 'newNFSe',
    component: () =>
      import(
        '@/modules/fiscal/modules/nfse/modules/nfse/ui/FiscalNFSeForm.vue'
      ),
    meta: {
      pageTitle: 'Cadastrar NFS-e',
    },
  },
  {
    path: 'edit/:id',
    name: 'editNFSe',
    props: true,
    component: () =>
      import(
        '@/modules/fiscal/modules/nfse/modules/nfse/ui/FiscalNFSeForm.vue'
      ),
    meta: {
      pageTitle: 'Editar NFS-e',
    },
  },
];
