import type { ToastProps } from 'vue-toast-notification';

import { openFileUrl } from '@/shared/print';
import { useToast } from 'vue-toast-notification';
import { ref } from 'vue';

import 'vue-toast-notification/dist/theme-sugar.css';
import { setShowLoader, showLoader } from '../shared/loader';

type ToastType = 'success' | 'error' | 'warning' | 'info' | 'open' | 'default';

const options = {
  position: 'top-right',
  onDismiss: () => {
    isOpen.value = false;
  },
} as ToastProps;
const $toast = useToast();
const isOpen = ref(false);

const success = (msg: string, url?: string) => {
  if (!url) return showToast('success', msg, {});

  const opt = {
    ...options,
    onClick() {
      openFileUrl(url);
    },
  };

  showToast('success', msg, opt);
};

const error = (msg: string, opt: ToastProps = {}) => {
  showToast('error', msg, opt);

  if (showLoader.value) setShowLoader(false);
};

const clear = () => {
  $toast.clear();

  isOpen.value = false;
};

const showToast = (type: ToastType, msg: string, opt: ToastProps) => {
  $toast[type](msg, { ...options, ...opt });

  isOpen.value = true;
};

export default {
  success,
  warning: (msg: string, opt: ToastProps = {}) =>
    showToast('warning', msg, opt),
  info: (msg: string, opt: ToastProps = {}) => showToast('info', msg, opt),
  default: (msg: string, opt: ToastProps = {}) =>
    showToast('default', msg, opt),
  error,
  clear,
  isOpen,
};
