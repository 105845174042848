import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'situations',
    meta: {
      packages: [Packages.OS]
    },
    children: [
      {
        path: 'new',
        name: 'newSituation',
        component: () =>
          import(
            '@/modules/document/modules/service-order/modules/situations/ui/DocumentSituationForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar situação'
        }
      },
      {
        path: 'edit/:id',
        name: 'editSituation',
        props: true,
        component: () =>
          import(
            '@/modules/document/modules/service-order/modules/situations/ui/DocumentSituationForm.vue'
          ),
        meta: {
          pageTitle: 'Editar situação'
        }
      }
    ]
  }
];
