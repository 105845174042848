import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'purchase-order',
    meta: {
      packages: [Packages.PURHASE_ORDER]
    },
    children: [
      {
        path: 'new',
        name: 'newPurchaseOrder',
        component: () =>
          import(
            '@/modules/document/modules/purchase-order/ui/DocumentPurchaseOrderForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar ordem de compra'
        }
      },
      {
        path: 'edit/:id',
        name: 'editPurchaseOrder',
        props: true,
        component: () =>
          import(
            '@/modules/document/modules/purchase-order/ui/DocumentPurchaseOrderForm.vue'
          ),
        meta: {
          pageTitle: 'Editar Ordem de compra'
        }
      }
    ]
  }
];
