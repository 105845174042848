import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'nfce',
    meta: {
      packages: [Packages.NFCE]
    },
    children: [
      {
        path: 'new',
        name: 'fiscalNFCeNew',
        component: () =>
          import('@/modules/fiscal/modules/nfce/ui/FiscalNFCeForm.vue'),
        meta: {
          pageTitle: 'Cadastrar NFC-e'
        }
      },
      {
        path: 'edit/:id',
        name: 'fiscalNFCeEdit',
        props: true,
        component: () =>
          import('@/modules/fiscal/modules/nfce/ui/FiscalNFCeForm.vue'),
        meta: {
          pageTitle: 'Editar NFC-e'
        }
      }
    ]
  }
];
