export default [
  {
    path: 'vehicles',
    children: [
      {
        path: 'new',
        name: 'newVehicle',
        component: () =>
          import(
            '@/modules/fiscal/modules/mdfe/modules/vehicles/ui/FiscalMdfeVehicleForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar veículo'
        }
      },
      {
        path: 'edit/:id',
        name: 'editVehicle',
        props: true,
        component: () =>
          import(
            '@/modules/fiscal/modules/mdfe/modules/vehicles/ui/FiscalMdfeVehicleForm.vue'
          ),
        meta: {
          pageTitle: 'Editar veículo'
        }
      }
    ]
  }
];
