import type { Plugin, PluginContext } from './index';

export type CurrentBrowser = {
  isIE?: boolean;
  isIOS?: boolean;
  isEdge?: boolean;
  isOpera?: boolean;
  isSafari?: boolean;
  isChrome?: boolean;
  isFirefox?: boolean;
  isChromeIOS?: boolean;
};

export const CurrentBrowser: Plugin = {
  install(this: any, { $z }: PluginContext) {
    const browser: CurrentBrowser = {};

    browser.isOpera =
      navigator.userAgent.indexOf(' OPR/') >= 0 ||
      /Opera/.test(navigator.userAgent);
    browser.isEdge = /Edge/.test(navigator.userAgent);
    browser.isFirefox = /Firefox/.test(navigator.userAgent);
    browser.isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      /constructor/i.test((window as any).HTMLElement);
    browser.isIE =
      navigator.userAgent.indexOf('MSIE') > -1 ||
      navigator.userAgent.indexOf('rv:') > -1;
    browser.isChrome = /Google Inc/.test(navigator.vendor);
    browser.isChromeIOS = /CriOS/.test(navigator.userAgent);
    browser.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    $z.currentBrowser = browser;
  },
};
