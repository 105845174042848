import type { ClientData } from '@/modules/account/modules/company/entities/client-data';
import type {
  AddressData,
  PhoneCollection,
  PhoneData,
} from '@/modules/core/entities';
import type { Subscription } from '../entities/subscription-data';

import ApiService from '@/external/services/ApiService';
import { defineStore } from 'pinia';
import { onlyNumbers } from '@/shared/format';
import { ref } from 'vue';
import { CRT } from '../enums/crt';
import { DanfePrintType } from '@/modules/fiscal/modules/fiscal-configuration/entities/enum/danfe-print-type';
import { WholesaleDocuments } from '@/modules/core/wholesale/entities/enum/wholesale-documents';
import {
  ParentModule,
  ParentModuleCollection,
} from '@/modules/application/store/cached/types';

enum Endpoints {
  getApplicationClient = 'application.get-client',
  putApplicationClient = 'application.put-client',
  uploadImage = 'core.upload-image',
  uploadCertificate = 'fiscal.upload-certificate',
  getCurrentSubscription = 'application.get-current_subscription',
  getMenu = 'application.get-menu',
}

type Image = {
  image?: ArrayBuffer | Buffer | string;
  maxWidth: string;
  maxHeight: string;
};

export default defineStore('accountCompany', {
  state: () => ({
    errors: {
      hasError: false,
    },
    hasPaf: false,
    image: { maxWidth: '1080', maxHeight: '1080' } as Image,
    person: {
      phoneCollection: [] as PhoneCollection,
    },
    phoneCollection: {
      countryCode: null,
      areaCode: null,
      number: null,
      type: null,
      id: null,
    },
    originalSerial: '',
    account: {
      serial: '',
      name: '',
      businessName: '',
      identification: '',
      emailMonthlySend: '',
      image: '',
      phoneCollection: [{}],
      documentConfiguration: {
        GR: {
          useWholesale: false,
          allowChangeSeller: true,
          doc: WholesaleDocuments.MANAGERIAL,
        },
        OS: {
          useWholesale: false,
          allowChangeSeller: true,
          doc: WholesaleDocuments.SERVICE_ORDER,
        },
        PEV: {
          useWholesale: false,
          allowChangeSeller: true,
          doc: WholesaleDocuments.SALE,
        },
        ORC: {
          useWholesale: false,
          allowChangeSeller: true,
          doc: WholesaleDocuments.ESTIMATE,
        },
        NFE: {
          useWholesale: false,
          allowChangeSeller: true,
          doc: WholesaleDocuments.NFE,
        },
        NFCE: {
          useWholesale: false,
          allowChangeSeller: true,
          doc: WholesaleDocuments.NFCE,
        },
      },
      fiscal: {
        emissor: {
          inscricaoEstadual: '',
          inscricaoMunicipal: '',
          CNAE: '',
          optanteSimples: false,
          aliquotaSimples: 0,
          CRT: CRT.NORMAL,
          isCertA3: false,
          certificado: {
            nome: '',
          },
        },
      },
      addressCollection: [
        {
          identification: 'Principal',
          street: '',
          neighbourhood: '',
          number: '',
          complement: '',
          zipCode: {
            zipCode: '',
            city: {
              name: '',
              state: {
                name: '',
              },
            },
          },
        } as AddressData,
      ],
      contactName: '',
      email: '',
      configuration: { danfePrintType: DanfePrintType.A4_PORTRAIT },
    } as ClientData,
    subscription: {} as Subscription,
    defaultDanfePrintTypes: [
      DanfePrintType.LABEL,
      DanfePrintType.SIMPLIFIED,
      DanfePrintType.A4_PORTRAIT,
      DanfePrintType.SIMPLIFIED_LABEL,
    ],
    modules: [] as ParentModuleCollection,
    subscriptionRoutes: [] as string[],
  }),
  getters: {
    checkIsMei: (state) => state.account.fiscal?.emissor?.CRT === CRT.MEI,
    checkIfIsOptanteSimples: (state) => {
      const isCrtSimples = [CRT.SIMPLE, CRT.MEI].includes(
        state.account.fiscal?.emissor?.CRT,
      );
      return isCrtSimples && state.account.fiscal?.emissor?.optanteSimples;
    },
    getAccountCertificateIsA3(): boolean {
      return this.account.fiscal.emissor.isCertA3 as boolean;
    },
    getAccountIsSimpleOptant(): boolean {
      return this.account.fiscal.emissor.optanteSimples;
    },
    getIsRuralProducer(): boolean {
      return this.account.fiscal.emissor.isRuralProducer;
    },
    getSimplesOptanteAliquota(): number {
      return this.account.fiscal.emissor.aliquotaSimples * 100;
    },
    isEnabledNT2023004(): boolean {
      return this.account.fiscal.emissor.NT2023004;
    },
    isEnabledDecreto56670(): boolean {
      return this.account.fiscal.emissor.decreto56670;
    },
    getDefaultDavPrintType(): string {
      return this.account.configuration.defaultDavPrintType;
    },
  },
  actions: {
    async getApplicationClient() {
      const { data } = await ApiService.post(Endpoints.getApplicationClient);

      if (!data.addressCollection.length) {
        delete data.addressCollection;
      }

      if (data.addressCollection?.[0].number === 'S/N') {
        data.addressCollection[0].noNumber = true;
      }

      if (data.fiscal.emissor.optanteSimples == null) {
        data.fiscal.emissor.optanteSimples = false;
        data.fiscal.emissor.CRT = CRT.NORMAL;
        data.fiscal.emissor.aliquotaSimples = 0;
      }

      if (data.fiscal.emissor.optanteSimples) {
        data.fiscal.emissor.aliquotaSimples *= 100;
      }

      if (data.fiscal.emissor.codigoMunicipio == null) {
        data.fiscal.emissor.codigoMunicipio = 0;
      }

      this.account = {
        ...this.account,
        ...data,
        documentConfiguration: {
          ...this.account.documentConfiguration,
          ...data.documentConfiguration,
        },
      };

      this.originalSerial = this.account.serial;

      this.hasPaf = data.hasPaf;
      return this.account;
    },
    async save() {
      const aliquotaRecalculated = ref<number>(0);

      if (this.image.image) {
        this.account.image = await this.uploadImage(this.image);
      }

      if (this.account.fiscal.emissor.optanteSimples) {
        this.account.fiscal.emissor.aliquotaSimples /= 100;
        aliquotaRecalculated.value = this.getSimplesOptanteAliquota ?? 0;
      }

      this.account.phoneCollection = this.person.phoneCollection;

      const request = {
        ...this.account,
        identification: onlyNumbers(this.account.identification),
        phoneCollection: this.person.phoneCollection.map((phone: PhoneData) => {
          return {
            type: phone.type,
            number: onlyNumbers(phone.areaCode + phone.number),
          };
        }),
      };

      if (request.fiscal.emissor.codigoMunicipio == null) {
        request.fiscal.emissor.codigoMunicipio = 0;
      }

      if (request.fiscal.emissor.CRT?.id) {
        request.fiscal.emissor.CRT = request.fiscal.emissor.CRT.id;
      }

      const { certificado } = this.account.fiscal.emissor;

      if (certificado?.file && certificado?.password) {
        await this.uploadCertificate(<any>certificado);
      }

      await ApiService.post(Endpoints.putApplicationClient, request);

      if (this.account.fiscal.emissor.optanteSimples) {
        this.account.fiscal.emissor.aliquotaSimples =
          aliquotaRecalculated.value;
      }
    },
    async uploadImage(payload: Image) {
      const formData = new FormData();
      formData.append('image', new Blob([payload.image!]), 'image.jpg');
      formData.append('maxWidth', payload.maxWidth!);
      formData.append('maxHeight', payload.maxHeight!);

      const { data } = await ApiService.post(Endpoints.uploadImage, formData);

      return data['hash-image'];
    },
    async uploadCertificate(payload: { password: string; file: File }) {
      const formData = new FormData();
      formData.append('file', new Blob([payload.file!]), payload.file.name);
      formData.append('password', payload.password!);

      const { data } = await ApiService.post(
        Endpoints.uploadCertificate,
        formData,
      );

      return data;
    },
    getStateAcronym() {
      const [address] = this.account.addressCollection;
      return address.zipCode.city.state.acronym;
    },
    async getCurrentSubscription(): Promise<Subscription> {
      const { data } = await ApiService.post(Endpoints.getCurrentSubscription);
      this.subscription = data;
      return data as Subscription;
    },
    async generateSubscriptionRoutes() {
      this.subscriptionRoutes = [
        '/404',
        '/sign-in',
        '/reseller-authenticate',
        '/forgot-password',
        '/companies-menu',
        '/support/password-reset',
        '/terms',
        '/sign-up',
        '/user/profile',
        '/report'
      ];

      const generateUriByMenu = async (modules: ParentModule, currentPath = '') => {
        const fullPath = currentPath + (modules?.uri ?? '');

        if (modules.children && modules.children.length > 0) {
          for (let child of modules.children) {
            if (await generateUriByMenu(child as ParentModule, fullPath)) {
              return true;
            }
          }
        }

        await this.subscriptionRoutes.push(fullPath);

        return false;
      };

      for (let module of this.modules) {
        await generateUriByMenu(module)
      }

      return this.modules;
    },
    async getMenu() {
      if (this.modules.length) return this.modules;
      const { data } = await ApiService.post(Endpoints.getMenu, {
        version: 'v2',
      });
      this.modules = data;
      return this.generateSubscriptionRoutes();
    },
  },
});
