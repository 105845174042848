import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'device',
    name: 'fiscalDevices',
    component: () => import('./ui/DevicesForm.vue'),
    meta: {
      pageTitle: '',
      packages: [Packages.FISCAL]
    },
  },
];
