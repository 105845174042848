import { Modal as BootstrapModal } from 'bootstrap';
type Modal = BootstrapModal & {
  _scrollBar?: { reset: () => void };
};

import { h, nextTick, ref } from 'vue';
import type { App, Component, ComponentPublicInstance, Ref } from 'vue';

import { createGlobalNode, removeGlobalNode } from '@/shared/global-nodes';
import { createChildApp } from '@/shared/create-child-app';

import type { Plugin, PluginContext, PluginObject } from '.';
import type { ComponentProps } from '@/shared/component-props';

export type ModalProps<T extends Component> = {
  component: T;
  componentProps?: ComponentProps<T>;
  backdrop?: boolean;
  preventClose?: (chain: ModalChain<T>) => boolean;
};

export type GlobalModal = <T extends Component>(
  pluginProps: ModalProps<T>,
) => ModalChain<T>;

export type GlobalModalPromise = <
  R extends any = any,
  T extends Component = Component,
>(
  pluginProps: ModalProps<T>,
) => Promise<R>;

interface ModalPluginObject extends PluginObject {
  /** @deprecated use Modal.instance instead */
  create: GlobalModal;
  instance: GlobalModal;
  async: GlobalModalPromise;
}

type EventCallback = (payload?: any) => void;
export type ModalChain<T> = {
  onOk: (callback: EventCallback) => ModalChain<T>;
  onCancel: (callback: EventCallback) => ModalChain<T>;
  onDismiss: (callback: EventCallback) => ModalChain<T>;
  onMounted: (callback: EventCallback) => ModalChain<T>;
  dialogRef: Ref<(T & ComponentPublicInstance) | undefined>;
  emitted: Ref<boolean>;
};

const instance = function (parentApp: App) {
  return <T extends Component>(pluginProps: ModalProps<T>): ModalChain<T> => {
    let app: App | null;
    let chain = {} as ModalChain<T>;

    const emitted = ref(false);

    const el = createGlobalNode('dialog');

    const { component: DialogComponent, componentProps } = pluginProps;
    const dialogRef = ref<T & ComponentPublicInstance>(),
      modalInstance = ref<Modal | null>(null);

    const listeners = {
        ok: [] as EventCallback[],
        cancel: [] as EventCallback[],
        dismiss: [] as EventCallback[],
        mounted: [] as EventCallback[],
      },
      onOk = (callback: EventCallback) => {
        listeners.ok.push(callback);
        return chain;
      },
      onCancel = (callback: EventCallback) => {
        listeners.cancel.push(callback);
        return chain;
      },
      onDismiss = (callback: EventCallback) => {
        listeners.dismiss.push(callback);
        return chain;
      },
      onMounted = (callback: EventCallback) => {
        listeners.mounted.push(callback);
        return chain;
      };

    const fireEventListeners = (callbacks: EventCallback[], payload?: any) => {
      callbacks
        // .concat(listeners.dismiss)
        .forEach((callback) => callback(payload));
    };

    const destroy = () => {
      !emitted.value && fireEventListeners(listeners.cancel);
      modalInstance?.value?.dispose();
      app?.unmount();
      removeGlobalNode(el);
      app = null;
    };

    const ok = (payload: any) => {
      emitted.value = true;
      fireEventListeners(listeners.ok, payload);
      modalInstance?.value?.hide();
    };

    const cancel = (payload: any) => {
      emitted.value = true;
      fireEventListeners(listeners.cancel, payload);
      modalInstance?.value?.hide();
    };

    const onHideModal = (event: Event) => {
      if (pluginProps?.preventClose?.(chain as ModalChain<T>) === true)
        return event.preventDefault();
    };

    const onVnodeMounted = async () => {
      if (!dialogRef.value) return;

      let options = {};

      if (pluginProps.backdrop) {
        options = {
          ...options,
          keyboard: false,
          backdrop: 'static',
        };
      }

      if (pluginProps.preventClose) {
        dialogRef.value?.$el.addEventListener('hide.bs.modal', onHideModal);
      }

      if (modalInstance.value) {
        modalInstance.value._scrollBar?.reset();
        modalInstance.value.dispose();
      }

      modalInstance.value = new BootstrapModal(dialogRef.value.$el, options);
      dialogRef.value?.$el.addEventListener('hidden.bs.modal', destroy);
      modalInstance.value.show();

      fireEventListeners(listeners.mounted, { dialogRef });
    };

    app = createChildApp(
      {
        name: 'GlobalDialog',
        setup: () => () =>
          h(DialogComponent, {
            ...(componentProps ?? {}),
            ref: dialogRef,
            onOk: ok,
            onCancel: cancel,
            onVnodeMounted: () => nextTick(onVnodeMounted),
          }),
      },
      parentApp,
    );

    app.mount(el);

    return (chain = {
      onOk,
      onDismiss,
      onMounted,
      onCancel,
      dialogRef,
      emitted,
    });
  };
};

const promisify =
  (modal: GlobalModal): GlobalModalPromise =>
  (pluginProps) =>
    new Promise((resolve, reject) =>
      modal(pluginProps).onOk(resolve).onCancel(reject),
    );

export const Modal = {
  install(this: ModalPluginObject, { $z, parentApp }: PluginContext) {
    $z.modal = instance(parentApp);
    $z.asyncModal = promisify($z.modal);

    if (this.__installed !== true) {
      this.create = this.instance = $z.modal;
      this.async = $z.asyncModal;
    }
  },
} as Plugin<ModalPluginObject>;

export default instance;
