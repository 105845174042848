import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'revenues',
    meta: {
      packages: [Packages.REVENUE]
    },
    children: [
      {
        path: 'new',
        name: 'newRevenue',
        component: () =>
          import('@/modules/finance/modules/revenues/ui/FinanceRevenuesForm.vue'),
        meta: {
          pageTitle: 'Cadastrar receita'
        },
        props: true
      },
      {
        path: 'edit/:id',
        name: 'editRevenue',
        component: () =>
          import('@/modules/finance/modules/revenues/ui/FinanceRevenuesForm.vue'),
        meta: {
          pageTitle: 'Editar receita'
        },
        props: true
      }
    ]
  }
];
