export const headerLight = {
  theme: 'light',
  theme_configuration: {
    general: {
      mode: 'light',
      layout: 'light-header'
    },
    header: {
      display: true,
      default: {
        container: 'fluid',
        fixed: {
          desktop: true,
          mobile: false
        }
      }
    },
    sidebar: {
      display: false,
      default: {
        minimize: {
          desktop: {
            enabled: true,
            default: true,
            hoverable: true
          }
        }
      }
    },
    pageTitle: {
      display: true,
      breadcrumb: true,
      direction: 'column'
    },
    content: {
      container: 'fixed'
    }
  },
}

export const sidebarLight = {
  ...headerLight,
  theme_configuration: {
    ...headerLight.theme_configuration,
    general: {
      ...headerLight.theme_configuration.general,
      layout: 'light-sidebar'
    },
    sidebar: {
      ...headerLight.theme_configuration.sidebar,
      display: true,
    }
  }
}

export const sidebarDark = {
  ...sidebarLight,
  theme: 'dark',
  theme_configuration: {
    ...sidebarLight.theme_configuration,
    general: {
      ...sidebarLight.theme_configuration.general,
      mode: 'dark'
    },
  }
}

export const headerDark = {
  ...headerLight,
  theme: 'dark',
  theme_configuration: {
    ...headerLight.theme_configuration,
    general: {
      ...headerLight.theme_configuration.general,
      mode: 'dark'
    },
  }
}

export const getLayoutConfiguration = (layout: string) => {
  switch (layout) {
    case 'light-header':
      return headerLight;
    case 'dark-header':
      return headerDark;
    case 'dark-sidebar':
      return sidebarDark;
    case 'light-sidebar':
      return sidebarLight;
    default:
      return headerLight;
  }
};