import { useAccountCompanyStore } from '@/modules/account/modules/company/store';
import type { Nullable } from './types';
import { date } from '@/shared/date';
import { isNull } from 'lodash';
import { storeToRefs } from 'pinia';

export const cpfCnpj = (valor?: string): string => {
  if (!valor) return '';

  if (valor.length <= 11) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return valor.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
};

export const formatCurrency = (
  amount: Nullable<number | string> = 0,
  decimalCount: number = 2,
): string => {
  const decimalStyle = 'currency';

  return intlFormat(amount, decimalCount, decimalStyle);
};

export const formatDecimal = (
  amount: Nullable<number | string> = 0,
  params?: { isPrice?: Boolean; isQuantity?: Boolean },
  decimalCount: number = 2,
): string => {
  let decimalStyle = 'decimal';

  const { account } = storeToRefs(useAccountCompanyStore());
  const { decimalPrice, decimalQuantity } = account.value.fiscal.emissor;

  if (params?.isQuantity) {
    return intlFormat(amount, decimalQuantity, decimalStyle);
  }

  if (params?.isPrice) {
    decimalStyle = 'currency';
    return intlFormat(amount, decimalPrice, decimalStyle);
  }

  return intlFormat(amount, decimalCount, decimalStyle);
};

export const intlFormat = (
  value: number | string | null,
  decimalCount: number,
  style: string,
): string => {
  const parsedAmount = typeof value === 'string' ? parseFloat(value) : value;

  if (!parsedAmount && parsedAmount !== 0) return '';

  if (isNull(parsedAmount) || (parsedAmount && isNaN(parsedAmount))) return '';

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: style as keyof Intl.NumberFormatOptionsStyleRegistry | undefined,
    currency: 'BRL',
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  });

  return formatter.format(parsedAmount);
};

export const convertDateTime = (
  value: any,
  format = 'DD/MM/YYYY HH:mm',
): string => {
  return convertDate(value, format);
};

export const convertDate = (value: any, format = 'DD/MM/YYYY'): string => {
  if (!value) return '';
  return date(value).format(format);
};

export const valorUS = (valor: string = '0'): number => {
  let valorFormatado = valor;
  if (typeof valor === 'string') {
    valorFormatado = valor.split('.').join('');
    return parseFloat(valorFormatado.replace(',', '.').replace('R$ ', ''));
  }
  return Number(valor);
};

export const convertToNumber = (number: string): number => {
  let newValue = number;
  if (!number.length) {
    return 0;
  }

  if (newValue.includes(',')) {
    newValue = newValue.replace(/\./g, '').replace(/,/g, '.');
  }

  return parseFloat(newValue);
};

export const onlyNumbers = (
  string: string | number | undefined | null,
): string => {
  if (!string) return '';
  if (typeof string === 'number') string = String(string);
  return string.replace(/[^0-9]/g, '');
};

export const leftPad = (
  value: string | number,
  totalWidth: number,
  paddingChar = '0',
) => {
  const length = totalWidth - value.toString().length + 1;
  return Array(length).join(paddingChar || '0') + value;
};

export const removeTagsHTMLFromString = (string: string) => {
  return string.replace(/<[^>]+>/g, '');
};

export const isHTMLInString = (string: string) => {
  const regex = /<[a-z][\s\S]*>/i;
  return regex.test(string);
};

export function addZero(str: string) {
  return String(str).length < 3
    ? '0'.repeat(3 - String(str).length) + String(str)
    : String(str);
}
