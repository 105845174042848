/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'axios';
import toast from '@/ui/global/plugins/toast';
import { setShowLoader } from '@/ui/global/shared/loader';
import WebSocket from '@/infrastructure/modules/websocket';
import { confirm } from '@/ui/global/shared/message';
import axiosRateLimit from 'axios-rate-limit';

class ApiServiceV2NoAuth {
  protected static http: AxiosInstance;
  protected static version = 2;

  public static init() {
    this.http = axiosRateLimit(
      axios.create({
        baseURL: `${import.meta.env.VITE_APP_API_URL}v${this.version}/`,
        withCredentials: true,
      }),
      {
        maxRequests: 5,
        perMilliseconds: 2000,
      },
    );

    this.http.interceptors.request.use((config) => {
      config.headers['Socket-Id'] = WebSocket.getId();

      return config;
    });

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error);
        setShowLoader(false);

        if (error.response?.data?.error_name === 'NoModel') {
          confirm(error.response.data?.error_message, undefined, 'Ok', '');
          return Promise.reject(error);
        }

        if (error.response?.data?.error_message) {
          toast.clear();
          toast.error(error.response.data.error_message);
        }

        return Promise.reject(error);
      },
    );
  }

  public static getInstance() {
    return this.http;
  }

  public static async post<T = any, P = any>(
    url: string,
    params?: P,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.http.post<P, AxiosResponse<T>>(url, params, config);
  }
}

export default ApiServiceV2NoAuth;
