/* eslint-disable @typescript-eslint/no-explicit-any */
import Swal from 'sweetalert2';

export const confirm = (
  text = 'Deseja excluir este item?',
  title = 'Atenção!',
  confirmButtonText = 'Sim, excluir!',
  cancelButtonText = 'Não',
  html = '',
  width = '',
  onDestroyFunction = undefined as any,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    Swal.fire({
      title,
      text,
      html,
      width,
      iconHtml: '<i class="fas fa-exclamation text-warning"></i>',
      showCancelButton: cancelButtonText !== '',
      iconColor: '#F7A600',
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-sm btn-primary',
        cancelButton: 'btn btn-sm btn-transparent',
      },
      focusConfirm: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      didDestroy: onDestroyFunction,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

export const error = (
  text: string,
  title = 'Atenção!',
  confirmButtonText = 'Fechar',
  cancelButtonText?: string | undefined,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    Swal.fire({
      title,
      text,
      icon: 'error',
      showCancelButton: !!cancelButtonText,
      cancelButtonText,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-sm btn-primary',
        cancelButton: 'btn btn-sm btn-transparent',
      },
      focusConfirm: true,
      confirmButtonText: confirmButtonText,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

export const success = (
  text: string,
  title = 'Atenção!',
  confirmButtonText = 'Fechar',
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    Swal.fire({
      title,
      text,
      icon: 'success',
      showCancelButton: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-sm btn-primary',
      },
      confirmButtonText: confirmButtonText,
      focusConfirm: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

export const confirmOptions = (
  text: string,
  title = 'Atenção!',
  buttons: { label: string; cb: () => void }[],
  showIcon = true,
  otherOptions?: { html?: string; hasPrimaryButton?: boolean },
) => {
  Swal.fire({
    title,
    iconHtml: showIcon ? '<i class="fas fa-exclamation text-warning"></i>' : '',
    text,
    html: otherOptions?.html,
    iconColor: '#F7A600',
    showCloseButton: true,
    showCancelButton: !!buttons[1]?.label,
    showDenyButton: buttons.length > 2,
    focusConfirm: false,
    focusCancel: false,
    buttonsStyling: false,
    customClass: {
      denyButton: 'btn btn-sm btn-secondary',
      cancelButton: `btn btn-sm btn-${
        otherOptions?.hasPrimaryButton ? 'primary' : 'secondary'
      }`,
      confirmButton: 'btn btn-sm btn-secondary',
    },
    confirmButtonText: buttons[0]?.label,
    cancelButtonText: buttons[1]?.label,
    ...(buttons.length > 2 && { denyButtonText: buttons[2]?.label }),
  }).then((result) => {
    if (result.isDenied) {
      buttons[2]?.cb();
      return;
    }

    if (result.isConfirmed) {
      buttons[0]?.cb();
      return;
    }
    const dismiss = result.dismiss || ('' as string | undefined);
    if (dismiss == 'cancel') {
      buttons[1]?.cb();
    }
  });
};
