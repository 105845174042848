import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'categories',
    meta: {
      packages: [Packages.CATEGORY]
    },
    children: [
      {
        path: 'new',
        name: 'newCategory',
        component: () => import('./ui/FinanceCategoriesForm.vue'),
        meta: {
          pageTitle: 'Cadastrar categoria'
        }
      },
      {
        path: 'edit/:id',
        name: 'editCategory',
        component: () => import('./ui/FinanceCategoriesForm.vue'),
        meta: {
          pageTitle: 'Editar categoria'
        },
        props: true
      }
    ]
  }
];
