import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'goals',
    meta: {
      packages: [Packages.GOALS]
    },
    children: [
      {
        path: 'new',
        name: 'newGoal',
        component: () =>
          import('./ui/FinanceGoalsForm.vue'),
        meta: {
          pageTitle: 'Cadastrar meta'
        }
      },
      {
        path: 'edit/:id',
        name: 'editGoal',
        component: () =>
          import('./ui/FinanceGoalsForm.vue'),
        meta: {
          pageTitle: 'Editar meta'
        },
        props: true
      }
    ]
  }
];
