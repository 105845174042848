import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'fiscal-configuration',
    name: 'FiscalConfiguration',
    component: () => import('./ui/FiscalConfigurations.vue'),
    meta: {
      packages: [Packages.FISCAL]
    },
  },
];
