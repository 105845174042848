import SessionService from './SessionService';

const CACHE_SERVICE = 'cacheService';

export const buildKey = (prefix: string) => {
  return { key: prefix };
};

export const loadCache = (key?: string) => {
  const cacheService = SessionService.get(CACHE_SERVICE);

  if (!cacheService || !key) {
    return undefined;
  }

  const cache = JSON.parse(cacheService);

  if (!cache) return undefined;
  return cache[key] || undefined;
};

export const setCache = async (key: string, data: any) => {
  if (!key || !data) return;

  const cacheService = SessionService.get(CACHE_SERVICE);

  if (!cacheService) {
    return SessionService.set(
      CACHE_SERVICE,
      JSON.stringify({
        [key]: data,
      })
    );
  }

  SessionService.set(
    CACHE_SERVICE,
    JSON.stringify({
      ...JSON.parse(cacheService),
      [key]: data,
    })
  );
};

export const clearCache = (key: string) => {
  const cacheService = SessionService.get(CACHE_SERVICE) || '{}';
  return SessionService.set(
    CACHE_SERVICE,
    JSON.stringify({
      ...JSON.parse(cacheService),
      [key]: null,
    })
  );
};

export default { loadCache, setCache, clearCache, buildKey };
