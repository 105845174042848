import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '@/ui/global/assets/ts/components';

const initializeComponents = () => {
  setTimeout(() => {
    ToggleComponent.bootstrap();
    MenuComponent.bootstrap();
    ScrollComponent.bootstrap();
    DrawerComponent.bootstrap();
  }, 0);
};

const reinitializeComponents = () => {
  setTimeout(() => {
    ToggleComponent.reinitialization();
    MenuComponent.reinitialization();
    reinitializeScrollComponent();
    ScrollComponent.updateAll();
    DrawerComponent.reinitialization();
  }, 0);
};

const reinitializeScrollComponent = () => {
  ScrollComponent.reinitialization();
};

export {
  initializeComponents,
  reinitializeComponents,
  reinitializeScrollComponent,
};
