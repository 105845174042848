import { Packages } from "@/modules/account/modules/company/enums";

export default [
    {
      path: 'ecommerce',
      meta: {
        packages: [Packages.ECOMMERCE]
      },
      children: []
    }
  ];
  