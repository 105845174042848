export default [
  {
    path: '/reseller-authenticate',
    name: 'reseller-authenticate',
    component: () =>
      import('@/modules/login/modules/resellerAuthenticate/ui/components/resellerAuthenticate.vue'),
    meta: {
      pageTitle: 'Acessar conta de cliente',
    },
  },
];
