export const get = (key: string): string | null => {
  return window.localStorage.getItem(key);
};

export const set = (key: string, value: string): void => {
  window.localStorage.setItem(key, value);
};

export const remove = (key: string): void => {
  window.localStorage.removeItem(key);
};

export const clear = (): void => {
  window.localStorage.clear();
};

export default { get, set, remove, clear };
