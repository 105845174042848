import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'objects',
    meta: {
      packages: [Packages.OS]
    },
    children: [
      {
        path: 'new',
        name: 'newObject',
        component: () =>
          import(
            '@/modules/document/modules/service-order/modules/objects/ui/DocumentObjectForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar objeto'
        }
      },
      {
        path: 'edit/:id',
        name: 'editObject',
        props: true,
        component: () =>
          import(
            '@/modules/document/modules/service-order/modules/objects/ui/DocumentObjectForm.vue'
          ),
        meta: {
          pageTitle: 'Editar objeto'
        }
      }
    ]
  }
];
