import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'types-of-service',
    meta: {
      packages: [Packages.OS]
    },
    children: [
      {
        path: 'new',
        name: 'newTypeOfService',
        component: () =>
          import(
            '@/modules/document/modules/service-order/modules/types-of-service/ui/DocumentTypeOfServiceForm.vue'
          ),
        meta: {
          pageTitle: 'Cadastrar tipo de atendimento'
        }
      },
      {
        path: 'edit/:id',
        name: 'editTypeOfService',
        props: true,
        component: () =>
          import(
            '@/modules/document/modules/service-order/modules/types-of-service/ui/DocumentTypeOfServiceForm.vue'
          ),
        meta: {
          pageTitle: 'Editar tipo de atendimento'
        }
      }
    ]
  }
];
