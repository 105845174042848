import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'installment',
    meta: {
      packages: [Packages.INSTALLMENT]
    },
    children: [
      {
        path: 'new',
        name: 'newInstallment',
        component: () => import('./ui/FinanceInstallmentForm.vue'),
        meta: {
          pageTitle: 'Cadastrar parcelamento'
        }
      },
      {
        path: 'edit/:id',
        name: 'editInstallment',
        component: () => import('./ui/FinanceInstallmentForm.vue'),
        meta: {
          pageTitle: 'Editar parcelamento'
        },
        props: true
      }
    ]
  }
];
