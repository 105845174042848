import { Packages } from '@/modules/account/modules/company/enums';

export default [
  {
    path: 'group',
    meta: {
      packages: [Packages.GROUP]
    },
    children: [
      {
        path: 'new',
        name: 'registerGroupNew',
        component: () =>
          import('@/modules/register/modules/group/ui/RegisterGroupForm.vue'),
        meta: {
          pageTitle: 'Cadastrar grupo'
        }
      },
      {
        path: 'edit/:id',
        name: 'registerGroupEdit',
        props: true,
        component: () =>
          import('@/modules/register/modules/group/ui/RegisterGroupForm.vue'),
        meta: {
          pageTitle: 'Editar grupo'
        }
      }
    ]
  }
];
