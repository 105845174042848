export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import('@/modules/dashboard/modules/finance/ui/DashboardFinance.vue'),
    meta: {
      pageTitle: 'Dashboard',
    },
  },
];
